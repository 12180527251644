import React, { useEffect, useState } from 'react'
import HomeBackground from '../webpart/HomeBackground'
import Menu from '../webpart/Menu'
import Footer from '../home/Footer'
import FloatBtn from '../webpart/FloatBtn'
import { Collapse, List } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default function Attribution() {
    const [imageList, setImageList] = useState([])
    const [iconList, setIconList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        const imageListRequest = axios.get('/attribution/imageList.json')
        const iconListRequest = axios.get('/attribution/iconList.json')

        Promise.all([imageListRequest, iconListRequest]).then(res => {
            setImageList(res[0].data)
            setIconList(res[1].data)
        }).catch(err => {
            console.log(err)
        })
    }, []);

    return (
        <>
            <HomeBackground />
            <Menu />
            <div style={{ height: 200 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '3vw',
                    fontWeight: 'bold',
                }}>
                    <div style={{ width: '40%', textAlign: 'center', background: 'rgb(0,0,0,0.5)', borderRadius: 18, color: 'rgb(239, 176, 10)' }}>Attribution</div></div>
            </div>
            <div className='demographic-head white'>
                <div>
                    Thanks to the following authors for their support of VICulture Playground, including <span className='highlight-word'>Images</span> and <span className='highlight-word'>Icons</span>.
                </div>
            </div>
            <div style={{ background: 'rgb(255, 255, 255)' }}>
                <div style={{ width: '80vw', marginLeft: '10vw' }}>
                    <Collapse
                        size="large"
                        items={[
                            {
                                key: '1',
                                label: 'Images',
                                children: imageList ? <List size='small' dataSource={imageList} renderItem={(item) => <List.Item>{item.name} <Link to={item.src}>{item.src}</Link></List.Item>} />
                                    :
                                    'loading...'
                            },
                            {
                                key: '2',
                                label: 'Icons',
                                children: iconList ? <List size='small' dataSource={iconList} renderItem={(link) => <List.Item><Link to={link.href} title={link.title}>{link.title} created by {link.creator} - Flaticon</Link></List.Item>} />
                                    :
                                    'loading...'
                            },
                        ]}
                    />
                </div>
            </div>
            <div style={{ height: 100, background: `linear-gradient(to bottom, white, rgb(255,255,255,0.8))` }} />
            <Footer />
            <FloatBtn />
        </>
    )
}
