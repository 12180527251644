import * as React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};


export const MenuItem = ({ i }) => {
  const navigate = useNavigate()

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={()=>navigate(i.link)}
    >
      <div className="icon-placeholder">{i.icon}</div>
      <div className="text-placeholder">{i.name}</div>
    </motion.li>
  );
};
