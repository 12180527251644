import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = (props) => (
  <motion.ul variants={variants} style={{
    display:props.isOpen ? 'block' : 'none'
  }} className="sidebar-ul">
    {items.map(i => (
      <MenuItem i={i} key={i.id} />
    ))}
  </motion.ul>
);

const items = [
  {
    id: 0,
    name: 'Game',
    icon: (<img src="/side-menu-icon/game-controller.png" />),
    link: '/games/game-initial?mode=game'
  },
  {
    id: 1,
    name: 'Library Station',
    icon: (<img src="/side-menu-icon/graduation-hat.png" />),
    link: '/games/game-initial?mode=library'
  },
  {
    id: 2,
    name: 'Home',
    icon: (<img src="/side-menu-icon/home.png" />),
    link: '/'
  },
];
