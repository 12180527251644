import React from 'react';
import { Col, Layout, Row } from 'antd';
import ReactiveButton from 'reactive-button';

const { Header, Content, Footer } = Layout;

const borderColors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF"];

function GameRightPanel({ question, onResult, ref3 }) {
    return (
        <div style={{ position: 'fixed', width: '100vw', height: '25vh', background: 'linear-gradient(to bottom, transparent, black)', bottom: 0 }}>
            <Row className='game-answer' ref={ref3}>
                {question.answers.map((option, index) => (
                    <Col span={12} key={index} className='game-answer-col'>
                        <ReactiveButton rounded idleText={option.description} width={'70%'} height={'80%'} style={{ fontSize: '2vw' }} onClick={() => onResult(index)} />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default GameRightPanel;
