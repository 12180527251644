import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react'

export default function FloatBtn() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <FloatButton
            icon={<VerticalAlignTopOutlined />}
            onClick={scrollToTop} // Add the onClick handler here
            style={{
                position: 'fixed',
                right: '20px',
                bottom: '20px'
            }}
        />
    )
}
