import React, { useEffect, useState } from 'react'
import HomeBackground from '../webpart/HomeBackground'
import Menu from '../webpart/Menu'
import Footer from '../home/Footer'
import FloatBtn from '../webpart/FloatBtn'
import { Col, Row, Spin } from 'antd'
import './Demographic.css'
import AncestryTreemap from './AncestryTreemap'
import { motion } from 'framer-motion'

export default function Demographic() {
    const [vs2Load, setVs2Load] = useState(false)
    const [vs3Load, setVs3Load] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <HomeBackground />
            <Menu />
            <div style={{ height: 200 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '3vw',
                    fontWeight: 'bold',
                }}>
                    <div style={{ width: '40%', textAlign: 'center', background: 'rgb(0,0,0,0.5)', borderRadius: 18, color: 'rgb(239, 176, 10)' }}>The Diversity of Victoria</div></div>
            </div>
            <div className='demographic-head'>
                <div>
                    Victoria, is a vibrant mosaic of cultures, with residents tracing their ancestries to over <span className='highlight-word'>200</span> countries, birthplaces spanning the globe, and a diverse population of international students enriching its academic and social fabric.
                </div>
            </div>
            <div style={{ background: 'rgb(255,255,255,0.8)' }}>
                <div style={{ width: '80vw', marginLeft: '10vw' }}>
                    <div>
                        <div className='demographic-title'>Ancestries of Victoria</div>
                        <Row gutter={30}>
                            <Col span={10} className='demographic-col' style={{ background: 'url(/demographic/victoria-history.jpg)', backgroundSize: 'cover' }}>
                                <div className='demographic-col-cover' >
                                    <div>
                                        Explore the <span className='highlight-word'>ancestries</span> of Victoria and suburbs to learn the ethnic background of a resident or their parents or grandparents were or the cultural group they most identify with.
                                    </div>
                                </div>
                            </Col>
                            <Col span={14}>
                                <AncestryTreemap />
                            </Col>
                        </Row>
                    </div>
                    <div className='demographic-container'>
                        <div className='demographic-title right'>Birthplaces of Victorian</div>
                        <Row gutter={30}>
                            <Col span={14}>
                                {!vs2Load &&
                                    <div className='demographic-loading'>
                                        <Spin tip="Loading" size="large" >
                                        </Spin>
                                    </div>
                                }
                                <iframe src="https://data-visualisation.df0v5oq8d85a5.amplifyapp.com" style={{ width: '100%', height: 400, border: 'none', borderRadius: 18 }} onLoad={() => setVs2Load(true)} />
                            </Col>
                            <Col span={10} className='demographic-col' style={{ background: 'url(/demographic/different-victorian.jpg)', backgroundSize: 'cover' }}>
                                <div className='demographic-col-cover' >
                                    <div>
                                        Explore the diverse <span className='highlight-word'>birthplaces of residents</span> across Victoria suburbs. Victoria is a melting pot of cultures, with the most common country of birth being, India, China and England.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='demographic-container'>
                        <div className='demographic-title'>Where international students from?</div>
                        <Row gutter={30}>
                            <Col span={10} className='demographic-col' style={{ background: 'url(/demographic/international-students.jpg)', backgroundSize: 'cover' }}>
                                <div className='demographic-col-cover' >
                                    <div>
                                        Explore the <span className='highlight-word'>nationalities of international students</span> throughout Victoria. This visualisation map reflects the multicultural educational environment across the education state.
                                    </div>
                                </div>
                            </Col>
                            <Col span={14}>
                                {!vs3Load &&
                                    <div className='demographic-loading'>
                                        <Spin tip="Loading" size="large" >
                                        </Spin>
                                    </div>
                                }
                                <iframe src="https://data-visualisation.d1cpcz8l7mbocj.amplifyapp.com/" style={{ width: '100%', height: 400, border: 'none', borderRadius: 18 }} onLoad={() => setVs3Load(true)} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <Footer />
            <FloatBtn />
        </motion.div>
    )
}