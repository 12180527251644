import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import HomeBackground from '../webpart/HomeBackground'
import Menu from '../webpart/Menu';
import { motion } from 'framer-motion';
import TextArea from 'antd/es/input/TextArea';
import { Button, Col, ConfigProvider, Divider, Modal, Progress, Rate, Row, message } from 'antd';
import Footer from '../home/Footer';
import './CommunicationTips.css'
import { InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import FloatBtn from '../webpart/FloatBtn';

const randomSentences = [
    "The flavors in your culture's cuisine might not be to my taste, and some dishes have strong aromas.",
    "The architecture in that culture tends to lean towards more traditional styles.",
    "I find the language of that culture quite unique and challenging to grasp.",
    "I find the festivals from that culture have a lively ambiance and energetic atmosphere.",
    "Food from your culture is so boring and sometimes has a bad smell.",
    "The buildings in that culture are always old and ugly.",
    "I think that the language of that culture sounds weird and difficult to learn.",
    "I am so tired of the festivals from that culture because they are always crowded and noisy."
];

export default function CommunicationTips() {
    const inputRef = useRef(null)
    const API_KEY = 'AIzaSyANe2p6xtoYaryvmTib7nXDpNPo3repac4';
    const [translatedText, setTranslatedText] = useState('');
    const [score, setScore] = useState(0)
    const [percent, setPercent] = useState(50)
    const [btnLoading, setBtnLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputText, setInputText] = useState('')
    const [isEnglish, setIsEnglish] = useState(false)
    const gradeColor = {
        'very-negative': 'red',
        'negative': '#ff8f13',
        'little-negative': '#ffc010',
        'neutral': '#fff007',
        'little-positive': '#bed902',
        'positive': '#6aaa02',
        'very-positive': 'green',
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const checkInputText = () => {
        setBtnLoading(true)
        const text = inputRef.current.resizableTextArea.textArea.value
        if (text) {
            detectAndTranslate(text)
        } else {
            messageApi.error('The Input box cannot be empty!')
            setBtnLoading(false)
        }
    }
    const detectAndTranslate = async (inputText) => {
        setIsEnglish(false)
        try {
            // Detect language
            const detectRes = await axios.post(`https://translation.googleapis.com/language/translate/v2/detect?key=${API_KEY}`, {
                q: inputText
            });

            const detectedLanguage = detectRes.data.data.detections[0][0].language;

            // Translate text
            if (detectedLanguage !== 'en') {
                const translateRes = await axios.post(`https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`, {
                    q: inputText,
                    source: detectedLanguage,
                    target: 'en'
                });
                const translation = translateRes.data.data.translations[0].translatedText
                setTranslatedText(translation);
                getResult(translation)
            } else {
                setTranslatedText(inputText);
                setIsEnglish(true)
                getResult(inputText)// If already in English, no translation needed.
            }
        } catch (error) {
            console.error('Error translating text:', error);
            setTranslatedText('Translation error');
            message.error('Please try to enter a valid text!')
            setBtnLoading(false)
        }
    };

    const getResult = async (text) => {
        axios({
            method: 'GET',
            url: 'https://twinword-twinword-bundle-v1.p.rapidapi.com/sentiment_analyze/',
            params: {
                text: text
            },
            headers: {
                'X-RapidAPI-Key': 'f3f2d9b68bmshec86642286a1a56p1de67ejsn8a8df8640e7d',
                'X-RapidAPI-Host': 'twinword-twinword-bundle-v1.p.rapidapi.com'
            }
        }
        )
            .then(response => {
                if (response.data.result_code === '200') {
                    let score = response.data.score
                    setScore(score)
                    setPercent(50 + score * 50)
                    setIsModalOpen(true)
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setBtnLoading(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setBtnLoading(false)
    };
    const getGrade = () => {
        if (score >= -1 && score < -0.8) {
            return 'very-negative'
        } else if (score >= -0.8 && score < -0.3) {
            return 'negative'
        } else if (score >= -0.3 && score < -0.1) {
            return 'little-negative'
        } else if (score >= -0.1 && score <= 0.1) {
            return 'neutral'
        } else if (score > 0.1 && score <= 0.3) {
            return 'little-positive'
        } else if (score > 0.3 && score <= 0.8) {
            return 'positive'
        } else if (score > 0.8 && score <= 1) {
            return 'very-positive'
        }
    }
    const decodeHtml = (html) => {
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    const getRandomSentence = () => {
        const randomIndex = Math.floor(Math.random() * randomSentences.length);
        return randomSentences[randomIndex];
    }
    return (
        <>
            {contextHolder}
            <HomeBackground />
            <Menu />
            <div style={{ height: 200 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '3vw',
                    fontWeight: 'bold',
                }}>
                    <div style={{ width: '40%', textAlign: 'center', background: 'rgb(0,0,0,0.5)', borderRadius: 18, color: 'rgb(239, 176, 10)' }}>Communication Tips</div></div>
            </div>
            <div style={{ height: 100, background: 'linear-gradient(to bottom, transparent, rgb(0,0,0,0.5))' }} />
            <div style={{
                background: 'rgb(0,0,0,0.5)',
                zIndex: 10
            }}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    style={{
                        width: '80vw',
                        marginLeft: '10vw',
                        color: 'white'
                    }}>
                    <h2 style={{ marginTop: 0 }}>Enter <span className='highlight-word'>any text</span> in <span className='highlight-word'>any language</span> below and you will know if it is <span className='highlight-word'>respectful</span> and <span className='highlight-word'>appropriate</span>.</h2>
                    <ConfigProvider
                        theme={{
                            components: {
                                Input: {
                                    inputFontSize: 30
                                }
                            }
                        }}
                    >
                        <TextArea ref={inputRef} rows={5} placeholder="Max Length is 200" maxLength={200} showCount value={inputText} onChange={(e) => {
                            setInputText(e.target.value)
                        }} />
                    </ConfigProvider>
                    <Row style={{ marginTop: 40 }}>
                        <Col span={12}>
                            <Button size='large' ghost onClick={() => {
                                setInputText(getRandomSentence())
                            }} style={{ width: 300, height: 80 }} icon={<UndoOutlined />} loading={btnLoading}>Random Sample...</Button>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type='primary' size='large' onClick={checkInputText} style={{ width: 300, height: 80, fontSize: 40 }} loading={btnLoading}>{btnLoading ? 'Analysing...' : 'Analyse!'}</Button>
                        </Col>
                    </Row>

                </motion.div>
            </div>
            <div style={{ height: 200, background: 'linear-gradient(to top, rgb(255,255,255,0.8), rgb(0,0,0,0.5))' }} />
            <Modal title="The positivity is:" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={700}>
                <div style={{
                    textAlign: 'center',
                    marginTop: 30
                }}>
                    <Progress percent={percent} showInfo={false} size={[400, 20]} strokeColor={{
                        '0%': 'red',
                        '50%': 'yellow',
                        '100%': 'green',
                    }} />

                </div>
                <h2 style={{ color: gradeColor[getGrade()], textAlign: 'center' }}>{getGrade().replace('-', ' ')}</h2>
                {!isEnglish &&
                    <p><b>Translation in English: </b>{decodeHtml(translatedText)}</p>
                }
                <Divider style={{ color: 'gray', fontSize: 10 }}><InfoCircleOutlined /> This result is for reference only and may not be entirely accurate.</Divider>
            </Modal>
            <Footer />
            <FloatBtn />
        </>
    )
}


