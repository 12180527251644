import React from 'react'

export default function HomeBackground() {
    const videos = ['/melbourne.mp4', '/thailand.mp4', '/lantern.mp4', '/london.mp4'];

  const getRandomVideo = () => {
    const index = Math.floor(Math.random() * videos.length);
    return videos[index];
  };
    return (
        <>
            <video autoPlay loop muted style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                top: '0',
                objectFit: 'cover',
                zIndex: -1
            }}>
                <source src={"https://ie-project.s3.ap-southeast-2.amazonaws.com/home-background-video" + getRandomVideo()} type="video/mp4" />
            </video>
            <div style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                top: '0',
                background: 'rgb(0,0,0,0.1)',
                zIndex: 0
            }}>

            </div></>
    )
}
