// GameRouter.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GameStart from '../components/game-start/GameStart';
import GameInitial from '../components/game-start/GameInitial';
import GameMenu from '../components/game-menu/GameMenu';
import ErrorPage from '../components/error-page/ErrorPage';

export default function GameRouter() {
    return (
        <Routes>
            <Route index element={<GameStart />} />
            <Route path='game-initial' element={<GameInitial />} />
            <Route path='game-menu' element={<GameMenu />} />
            <Route path='*' element={<ErrorPage />}></Route>
        </Routes>
    );
}
