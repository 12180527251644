import React from 'react';
import './GameLayout.css';
import GameRouter from '../../router/GameRouter';
import { SideBar } from '../side-menu/SideBar';
import { motion } from 'framer-motion'
import BackButton from '../webpart/BackButton';
import GameBackground from '../webpart/GameBackground';
import { useNavigate } from 'react-router-dom';

export default function GameLayout() {
  const navigate = useNavigate()

  return (
    <div className='game-background'>
      <SideBar />
      <BackButton />
      <GameBackground />
      <div style={{ position: 'fixed', top: 0, textAlign: 'center', width: '100vw' }}>
        <motion.img initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }} src='/logo.jpg' alt='logo' style={{ height: 100, width: 200, cursor: 'pointer' }} onClick={() => navigate('/')} />
      </div>
      <div className="router-container">
        <GameRouter />
      </div>
    </div>
  );
}
