import { motion, useMotionValue, useTransform } from 'framer-motion';
import './FoodOngoingMain.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { notification, message, Row, Col, Modal, Spin, Tour, ConfigProvider, Progress } from 'antd'
import ReactiveButton from 'reactive-button'
import GameSummary from '../place-ongoing/GameSummary';
import { throttle } from 'lodash';

export default function FoodOngoingMain(props) {
    const { country } = props;
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const [foodList, setFoodList] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [currentFoodIndex, setCurrentFoodIndex] = useState(0);
    const [resultChecked, setResultChecked] = useState(false);
    const [api, notificationHolder] = notification.useNotification();
    const [messageApi, contextHolder] = message.useMessage()
    const [summary, setSummary] = useState(false)
    const [boxDisplay, setBoxDisplay] = useState(true)
    const [bgOngoing, setBgOngoing] = useState(null)
    const [questionState, setQuestionState] = useState(0)
    const [tourOpen, setTourOpen] = useState(false)
    const [dragProcessed, setDragProcessed] = useState(false);
    const foodTourOpen = localStorage.getItem('foodTourOpen')
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const xInput = [-100, 0, 100];
    const background = useTransform(x, xInput, [
        "linear-gradient(180deg, #E17F24 0%, #FF0F0F 100%)",
        "linear-gradient(180deg, #24D0E1 0%, #0F27FF 100%)",
        "linear-gradient(180deg, rgb(230, 255, 0) 0%, rgb(3, 209, 0) 100%)"
    ]);
    const color = useTransform(x, xInput, [
        "#FF0F0F",
        "#0F27FF",
        "rgb(3, 209, 0)"
    ]);
    const tickPath = useTransform(x, [10, 100], [0, 1]);
    const crossPath = useTransform(x, [-100, -10], [1, 0]);
    const tickOpacity = useTransform(x, [50, 100], [0, 1]);
    const crossOpacity = useTransform(x, [-100, -50], [1, 0]);

    const progressYesOpacity = useTransform(x, [0, 100], [0, 1]); // Opacity increases as box is dragged right
    const progressNoOpacity = useTransform(x, [-100, 0], [1, 0]);

    const steps = [
        {
            title: 'Drag Image Box',
            description: 'Drag the image to left or right to guess the food from.',
            cover: <video autoPlay loop muted src='https://ie-project.s3.ap-southeast-2.amazonaws.com/game-tour/food-game-teach.mp4' style={{ width: '100%', height: '100%' }} />,
            target: () => ref1.current,
        },
        {
            title: 'See the Progress',
            description: 'Check the progress of this round on the top.',
            target: () => ref2.current,
        }
    ]

    const modalItem = {
        content: (
            <>
                <Row gutter={20}>
                    <Col span={8}>
                        <img src={foodList[currentFoodIndex]?.image_url} alt={foodList[currentFoodIndex]?.name} style={{ width: '100%', borderRadius: 18 }} />
                    </Col>
                    <Col span={16}>
                        <h2>{foodList[currentFoodIndex]?.name}</h2>
                        <span><b>Country: </b>{foodList[currentFoodIndex]?.country}</span>
                        <p>{foodList[currentFoodIndex]?.description}</p>

                    </Col>
                </Row>
            </>
        ),
        footer: (_) => (
            <>
                <ReactiveButton rounded idleText={currentFoodIndex === 4 ? 'View Summary' : 'Next Question'} onClick={() => {
                    Modal.destroyAll()
                    if (currentFoodIndex === 4) {
                        setBgOngoing(null)
                        setSummary(true)
                        api.destroy()
                    } else {
                        api.destroy()
                        setCurrentFoodIndex(currentFoodIndex + 1)
                        setBoxDisplay(true)
                        setBgOngoing(null)
                    }
                }
                }
                />

            </>
        ),
        closeIcon: false,
        maskClosable: false,
        width: '50%'
    }

    const checkResult = useCallback((direction) => {
        if (!resultChecked && !dragProcessed && foodList.length > 0) {
            setDragProcessed(true);
            setResultChecked(true);
            api.destroy();
            Modal.destroyAll();

            const currentFood = foodList[currentFoodIndex];
            if (!currentFood) {
                console.error('No current food item found. This should not happen here.');
                return;
            }

            const isCorrectCountry = currentFood.country === country;
            const isRightDirection = direction === 'right';
            const correctResult = (isRightDirection && isCorrectCountry) || (!isRightDirection && !isCorrectCountry);

            setBgOngoing(correctResult ? 'correct-gradient' : 'incorrect-gradient');
            setQuestionState(questionState + 20)
            if (correctResult) {
                setCorrectAnswer(correctAnswer + 1);
                api['success']({ message: 'Correct Answer!' });
                Modal.success({ ...modalItem, title: 'Correct Answer!' });
            } else {
                api['error']({ message: 'Wrong Answer!' });
                Modal.error({ ...modalItem, title: 'Wrong Answer.' });
            }

            setBoxDisplay(false);
        }
    }, [resultChecked, dragProcessed, foodList, currentFoodIndex]);

    const throttledCheckResult = useCallback(throttle((direction) => {
        if (!dragProcessed) {
            checkResult(direction);
        }
    }, 5000), [checkResult, dragProcessed]);

    useEffect(() => {
        // Reset resultChecked and dragProcessed when currentFoodIndex changes.
        setResultChecked(false);
        setDragProcessed(false);
    }, [currentFoodIndex]);

    useEffect(() => {
        const unsubscribeX = x.onChange((latestX) => {
            if (dragProcessed) {
                return;
            }
            if (latestX > 150) {
                throttledCheckResult('right');
            } else if (latestX < -150) {
                throttledCheckResult('left');
            }
        });

        return () => {
            unsubscribeX();
            throttledCheckResult.cancel();
        };
    }, [dragProcessed, throttledCheckResult]);

    const onDragEnd = () => {
        x.set(0);  // Reset position after drag
        setResultChecked(false);  // Reset result checked flag after drag ends
    };

    useEffect(() => {
        axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/games/food?country=${country}`)
            .then(res => {
                const allFoods = res.data;
                if (allFoods && allFoods.length > 0) {
                    setFoodList(allFoods);
                    setCurrentFoodIndex(0);
                } else {
                    console.error('No foods found for the selected country.');
                }
            }).catch(err => {
                console.error('Error fetching food data:', err);
            });

        if (!foodTourOpen) {
            setTimeout(() => {
                setTourOpen(true);
                localStorage.setItem('foodTourOpen', true);
            }, 1500);
        }
    }, [country]);

    return (
        <>
            {contextHolder}
            {notificationHolder}
            <motion.div className="food-ongoing-container" style={{ background: background }} />
            {foodList.length > 0 && currentFoodIndex < foodList.length ?
                summary ?
                    <>
                        <motion.div
                            initial={{ scale: 0.2, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 1, ease: "easeOut" }}
                            style={{ background: 'rgba(255, 255, 255, 0.9)' }}
                            className="game-ongoing-container">
                            <div style={{ background: 'rgb(255,255,255,0.9)', width: '80vw', height: '80vh', position: 'absolute', top: '10vh', left: '10vw', borderRadius: 18 }}>
                                <GameSummary round={foodList.length} correct={correctAnswer} country={country} />
                            </div>
                        </motion.div>
                    </> :
                    <>
                        <div style={{ height: '20vh', paddingInlineStart: '10vw', paddingInlineEnd: '10vw', color: 'white', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10 }}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Progress: {
                                            colorText: 'white',
                                        },
                                    },
                                }}
                            >
                                <Progress ref={ref2} type="line" percent={questionState} />
                            </ConfigProvider>
                        </div>
                        <div style={{ height: '20%', fontSize: '3vw', textAlign: 'center', color: 'white', textShadow: '1px 1px black', marginTop: '2%' }}>
                            Is it a food of <span style={{ color: '#EFB00A' }}>{country}</span>?
                        </div>
                        <motion.div
                            className="box"
                            style={{ x, zIndex: 1, border: '30px solid white' }}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            initial={{ scale: 0.2, opacity: 0 }}
                            animate={{ scale: boxDisplay ? 1 : 0, opacity: boxDisplay ? 1 : 0 }}
                            transition={{ duration: 0.5, ease: "easeOut" }}
                            onDrag={(event, info) => {
                                if (Math.abs(info.point.y - info.offset.y) < 20) {  // Check minimal vertical movement to ignore diagonal drags
                                    if (info.point.x > 150) {
                                        checkResult('right');
                                    } else if (info.point.x < -150) {
                                        checkResult('left');
                                    }
                                }
                            }}
                            onDragEnd={onDragEnd}
                        >
                            <div ref={ref1} style={{ height: '100%', width: '100%' }}>
                                <img src={foodList[currentFoodIndex].image_url} alt={foodList[currentFoodIndex].name} style={{ width: '100%', height: '100%', borderRadius: 18, boxShadow: '0 0 10px black' }} />
                            </div>
                            <div className='swipe-card-cover'>
                                <div className='cover-content'>
                                    <div className='card-title'>{foodList[currentFoodIndex].name}</div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            className='progress-no'
                            style={{
                                width: '20vh',
                                height: '20vh',
                                position: 'absolute',
                                left: '15%',
                                top: '40vh',
                                background: 'white',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: progressNoOpacity
                            }}>
                            <motion.svg
                                initial={{ opacity: 0 }}
                                style={{ opacity: crossOpacity }}
                                viewBox="0 0 50 50">
                                <motion.path
                                    fill="none"
                                    strokeWidth="2"
                                    stroke={color}  // Use the same color transform as the background
                                    d="M17,17 L33,33"
                                    strokeDasharray="0 1"
                                    style={{ pathLength: crossPath }}
                                />
                                <motion.path
                                    fill="none"
                                    strokeWidth="2"
                                    stroke={color}  // Use the same color transform as the background
                                    d="M33,17 L17,33"
                                    strokeDasharray="0 1"
                                    style={{ pathLength: crossPath }}
                                />
                            </motion.svg>
                        </motion.div>
                        <motion.div
                            className='progress-yes'
                            style={{
                                width: '20vh',
                                height: '20vh',
                                position: 'absolute',
                                right: '15%',
                                top: '40vh',
                                background: 'white',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: progressYesOpacity
                            }}>
                            <motion.svg
                                initial={{ opacity: 0 }}
                                style={{ opacity: tickOpacity }}
                                viewBox="0 0 50 50">
                                <motion.path
                                    fill="none"
                                    strokeWidth="2"
                                    stroke={color}  // Use the same color transform as the background
                                    d="M14,26 L 22,33 L 35,16"
                                    strokeDasharray="0 1"
                                    style={{ pathLength: tickPath }}
                                />
                            </motion.svg>
                        </motion.div>
                        <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} /></> : <>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <div style={{ background: 'white', padding: 30, borderRadius: 18 }}>
                            <Spin size="large">
                            </Spin>
                        </div>
                    </div>
                </>}
        </>
    );
};