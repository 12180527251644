import React from 'react'
import { Divider, Row, Col } from 'antd'
import './Footer.css'
import { Link } from 'react-router-dom'
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, CopyrightOutlined } from '@ant-design/icons'

export default function Footer() {
    return (
        <div style={{ width: '90%', background: 'rgb(255,255,255,0.8)', padding: '5%' }}>
            <div style={{ marginTop: 50 }}>
                <Divider />
            </div>
            <Row gutter={50} style={{ height: 200, width: '80%', marginLeft: '10%' }}>
                <Col span={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to='/'><img src='/logo-gray.jpg' alt='logo-gray' style={{ width: '50%', cursor: 'pointer' }} /></Link>
                </Col>
                <Col span={6}>
                    <h3>Cultural Connection</h3>
                    <ul className='footer-ul'>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/calendar'>Festival Calendar</Link></li>
                        <li><Link to='/communication-tips'>Communication Tips</Link></li>
                    </ul>
                </Col>
                <Col span={6}>
                    <h3>Cultural Exploration</h3>
                    <ul className='footer-ul'>
                        <li><Link to='/games/game-initial?mode=game'>Quiz Games</Link></li>
                        <li><Link to='/games/game-initial?mode=library'>Library Station</Link></li>
                    </ul>
                </Col>
                <Col span={6}>
                    <h3>About VICulture Playground</h3>
                    <ul className='footer-ul'>
                        <li><Link to='/demographic'>Victorian Diversity</Link></li>
                        <li><Link to='/attribution'>Attribution</Link></li>
                    </ul>
                </Col>
            </Row>
            <Divider />
            <div style={{ textAlign: 'center', color: '#4e4d4c' }}>
                <CopyrightOutlined /> VICulture Playground, FIT5120-TP02, 2024 S1
            </div>
        </div>
    )
}
