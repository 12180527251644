import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button, Calendar, Col, ConfigProvider, Modal, Radio, Row, Select, Skeleton, Tag, Typography } from 'antd';
import './FestivalCalendar.css'
import Menu from '../webpart/Menu';
import axios from 'axios';
import Footer from '../home/Footer'
import { DotChartOutlined, DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import FloatBtn from '../webpart/FloatBtn';

export default function FestivalCalendar() {
    const [eventList, setEventList] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('Australia')
    const [festivalDetails, setFestivalDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [festivalEvent, setFestivalEvent] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const countryTag = {
        Australia: '#103957',
        China: '#FFBD59',
        England: '#BF6E85',
        India: '#EA8A04',
        Thailand: '#CC806B',
        Vietnam: '#db5225'
    }

    const countryBg = {
        Australia: '#2DB7F5',
        China: '#C84B39',
        England: '#8C52FF',
        India: '#FFDE59',
        Thailand: '#7ED957',
        Vietnam: '#5271FF'
    }
    const styles = {
        card: { height: '100%', width: '100%', borderRadius: 18 },
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(false)
        setFestivalDetails(null)
        axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/calendar?country=${selectedCountry}`).then(res => {
            setEventList(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [selectedCountry])

    const getListData = (value) => {
        const dateStr = value.format('YYYY-MM-DD');
        return eventList.filter(event => event.start_date === dateStr).map(event => ({
            id: event.id,
            content: event.name
        }));
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item, index) => (
                    <li key={index} onClick={() => {
                        getFestivalDetails(item.id)
                    }}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        colorPrimary: countryBg[selectedCountry],
                                        colorPrimaryHover: countryBg[selectedCountry],
                                        colorPrimaryActive: countryBg[selectedCountry],
                                        lineWidth: 0,
                                        defaultBg: countryTag[selectedCountry]
                                    },
                                },
                            }}
                        >
                            <Button style={{ border: 'none' }}>{item.content}</Button>
                        </ConfigProvider>
                    </li>
                ))}
            </ul>
        );
    };
    const onChange = (value) => {
        setSelectedCountry(value)
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const getFestivalDetails = (festivalId) => {
        setFestivalDetails(null)
        setLoading(true)
        axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/calendar/festival?id=${festivalId}`).then(res => {
            if (res.data.length > 0) {
                setFestivalDetails(res.data[0])
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const showModal = (e) => {
        setFestivalEvent(e)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <motion.video
                autoPlay
                muted
                src={`https://ie-project.s3.ap-southeast-2.amazonaws.com/calendar/${selectedCountry}.mp4`}
                // src={`/calendar/${selectedCountry}.mp4`}
                style={{
                    position: 'fixed',
                    width: '100vw',
                    top: 0,
                    zIndex: -1
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            />
            <div style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                top: '0',
                background: 'rgb(0,0,0,0.15)',
                zIndex: 0
            }}></div>
            <Menu />

            <div style={{ height: 200 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '3vw',
                    fontWeight: 'bold',
                }}>
                    <div style={{ width: '40%', textAlign: 'center', background: 'rgb(0,0,0,0.5)', borderRadius: 18, color: 'rgb(239, 176, 10)' }}>Festival Calendar</div></div>
            </div>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <div style={{
                    width: '80%',
                    padding: '2% 10% 2% 10%',
                    background: 'linear-gradient(to bottom,transparent, rgb(0,0,0,0.3))'
                }}>
                    <Row style={{ width: '100%' }} gutter={30}>
                        <Col span={13}>
                            <h1 style={{ fontSize: '2.5em', color: 'white' }}>Select a country to view the festivals:</h1>
                        </Col>
                        <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                showSearch
                                size='large'
                                defaultValue='Australia'
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                style={{
                                    width: '50%',
                                    display: 'inline-block'
                                }}
                                options={[
                                    {
                                        value: 'Australia',
                                        label: 'Australia',
                                    },
                                    {
                                        value: 'China',
                                        label: 'China',
                                    },
                                    {
                                        value: 'England',
                                        label: 'England',
                                    },
                                    {
                                        value: 'India',
                                        label: 'India',
                                    },
                                    {
                                        value: 'Thailand',
                                        label: 'Thailand',
                                    },
                                    {
                                        value: 'Vietnam',
                                        label: 'Vietnam',
                                    },
                                ]}
                            />
                        </Col>

                    </Row>
                </div>


                <div style={{
                    width: '80%',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                    position: 'relative',
                    background: 'rgb(0,0,0,0.3)',
                }}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <>
                                <div style={{ width: '100%', height: 150, background: festivalDetails ? 'rgb(0,0,0,0.4)' : 'rgb(0,0,0,0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 18 }}>
                                    {festivalDetails ?
                                        <Typography.Title level={2} style={{ fontSize: 40, textAlign: 'center', color: 'rgb(239, 176, 10)' }}>
                                            {festivalDetails.name}
                                        </Typography.Title>
                                        :
                                        <div style={{ width: '80%', color: 'white' }}>
                                            {!loading ?
                                                <span style={{ fontSize: 20 }}>Try to find any festival on the calendar with the <Tag color={countryTag[selectedCountry]}>Tag</Tag>.</span>
                                                :
                                                <Skeleton.Input active={loading} block />
                                            }
                                        </div>
                                    }

                                </div>
                                <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', background: festivalDetails ? 'rgb(0,0,0,0.4)' : 'rgb(0,0,0,0.1)', borderRadius: 18, marginTop: 30 }}>
                                    {festivalDetails ?
                                        <img src={festivalDetails.img_url} alt={festivalDetails.name} style={{ height: '95%', borderRadius: 18 }} />
                                        :
                                        <Skeleton.Image active={loading} />
                                    }

                                </div>
                                {festivalDetails ?
                                    <p style={{ background: 'white', marginTop: 30, borderRadius: 18, background: 'rgb(0,0,0,0.4)', color: 'white', padding: '20px 10% 20px 10%' }}>
                                        {festivalDetails.detail}
                                    </p> :
                                    <div style={{ marginTop: 30, borderRadius: 18, background: 'rgb(0,0,0,0.1)', padding: '20px 10% 20px 10%' }}>
                                        <Skeleton active={loading} />
                                    </div>
                                }

                                <Row style={{ width: '100%', marginTop: 30, background: festivalDetails ? 'rgb(0,0,0,0.4)' : 'rgb(0,0,0,0.1)', borderRadius: 18 }}>
                                    {festivalDetails ?
                                        festivalDetails.interesting_facts?.map((e, index) => (
                                            <>
                                                <Col span={12} key={index} className='calendar-desc-col'>
                                                    <div className='calendar-event-img' onClick={() => showModal(e)}>
                                                        <img src={e.img_url} alt={e.topic} />
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            background: 'linear-gradient(to top, rgba(0,0,0,0.5), transparent 90%)',
                                                            borderRadius: 18
                                                        }} />
                                                        <div style={{
                                                            position: 'absolute',
                                                            left: '10%',
                                                            bottom: '10%',
                                                            fontSize: 15,
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            maxWidth: '50%'
                                                        }}>{e.topic}</div>
                                                    </div>

                                                </Col>
                                            </>
                                        ))
                                        :
                                        Array(4).fill(null).map((_, index) => (
                                            <Col key={index} span={12} className="calendar-desc-col">
                                                <Skeleton.Node active={loading}>
                                                    <DotChartOutlined
                                                        style={{
                                                            fontSize: 40,
                                                            color: '#bfbfbf',
                                                        }}
                                                    />
                                                </Skeleton.Node>
                                            </Col>
                                        ))
                                    }

                                </Row>
                            </>

                        </Col>
                        <Col span={16}>
                            <motion.div>
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorText: 'white',
                                        },
                                        components: {
                                            Calendar: {
                                                fullBg: 'transparent',
                                            }
                                        }
                                    }}
                                >
                                    <Calendar cellRender={dateCellRender}
                                        headerRender={({ value, type, onChange, onTypeChange }) => {
                                            const start = 0;
                                            const end = 12;
                                            const monthOptions = [];
                                            let current = value.clone();
                                            const localeData = value.localeData();
                                            const months = [];
                                            for (let i = 0; i < 12; i++) {
                                                current = current.month(i);
                                                months.push(localeData.monthsShort(current));
                                            }
                                            for (let i = start; i < end; i++) {
                                                monthOptions.push(
                                                    <Select.Option key={i} value={i} className="month-item">
                                                        {months[i]}
                                                    </Select.Option>,
                                                );
                                            }
                                            const year = value.year();
                                            const month = value.month();
                                            const options = [];
                                            for (let i = 2018; i < 2030; i += 1) {
                                                options.push(
                                                    <Select.Option key={i} value={i} className="year-item">
                                                        {i}
                                                    </Select.Option>,
                                                );
                                            }
                                            return (
                                                <div
                                                    style={{
                                                        padding: 8,
                                                    }}
                                                >
                                                    <Row gutter={10}>
                                                        <Col span={2}>
                                                            <Button icon={<UpOutlined />} type='text' style={{ width: '100%' }} onClick={() => {
                                                                const now = value.clone().year(value.year() - 1)
                                                                onChange(now);
                                                            }} />
                                                            <Typography.Title level={1} style={{ margin: 0, textAlign: 'center', color: 'white', fontSize: '2.5vw' }}>
                                                                {value.year()}</Typography.Title>
                                                            <Button icon={<DownOutlined />} type='text' style={{ width: '100%' }} onClick={() => {
                                                                const now = value.clone().year(value.year() + 1)
                                                                onChange(now);
                                                            }} />
                                                        </Col>
                                                        <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Row style={{ width: '100%' }}>
                                                                <Col span={1}>
                                                                    <Button icon={<LeftOutlined />} type='text' style={{ height: '100%' }} onClick={() => {
                                                                        const now = value.clone().month(value.month() - 1)
                                                                        onChange(now);
                                                                    }} />
                                                                </Col>
                                                                <Col span={11}>
                                                                    <Typography.Title level={1} style={{ margin: 0, textAlign: 'center', fontSize: '3vw', color: 'white' }}>{getMonth(value.month())}</Typography.Title>
                                                                </Col>
                                                                <Col span={1}>
                                                                    <Button icon={<RightOutlined />} type='text' style={{ height: '100%' }} onClick={() => {
                                                                        const now = value.clone().month(value.month() + 1)
                                                                        onChange(now);
                                                                    }} />
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        }}
                                        onPanelChange={onPanelChange}
                                    />
                                </ConfigProvider>
                            </motion.div>
                        </Col>
                    </Row>
                </div>
                {
                    festivalEvent &&
                    <Modal title={festivalEvent.topic} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Row gutter={20}>
                            <Col span={8}>
                                <img src={festivalEvent.img_url} alt={festivalEvent.topic} style={{ borderRadius: 18, width: '100%' }} />
                            </Col>
                            <Col span={16}>
                                {festivalEvent.detail}
                            </Col>
                        </Row>
                    </Modal>
                }
            </motion.div>
            <div style={{ height: 200, background: `linear-gradient(to bottom, rgb(0,0,0,0.3), rgb(255,255,255,0.8))` }} />
            <Footer />
            <FloatBtn />
        </>
    )
}

const getMonth = (month) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return months[month].toUpperCase(); // Subtract 1 to convert month number to array index
}
