import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Map from 'react-map-gl';
import { useLocation } from 'react-router-dom';

export default function GameBackground() {
    const [modeStyle, setModeStyle] = useState('game')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        if (location.pathname === '/games/game-initial') {
            const mode = queryParams.get('mode');
            if (mode === 'library') {
                setModeStyle('library');
            } else {
                setModeStyle('game');
            }
        }
    }, [location]);
    return (
        <div style={{ background: 'black', width: '100vw', height: '100%' }}>
            {modeStyle === 'game' &&
                <motion.video
                    initial={{ y: '100vh' }} // start from below the screen
                    animate={{ y: '10vh' }}       // animate to the top of the screen
                    transition={{ delay: 0.5, duration: 2, ease: "easeOut" }} // clear timing for testing
                    loop
                    autoPlay
                    muted
                    style={{
                        position: 'fixed',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 0
                    }}
                    src='https://ie-project.s3.ap-southeast-2.amazonaws.com/universe.mp4'
                ></motion.video>}
            {modeStyle === 'library' &&
                <motion.video
                    initial={{ y: '100vh' }} // start from below the screen
                    animate={{ y: '25vh' }}       // animate to the top of the screen
                    transition={{ delay: 0.5, duration: 2, ease: "easeOut" }} // clear timing for testing
                    loop
                    autoPlay
                    muted
                    style={{
                        position: 'fixed',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 0
                    }}
                    src='https://ie-project.s3.ap-southeast-2.amazonaws.com/mars.mp4'
                ></motion.video>}
            <div style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                minWidth: '100%',
                minHeight: '100%',
                zIndex: -1,
                background: 'rgb(0,0,0,0.1)'
            }}>

            </div>
            {/* <motion.img src='/home-mode/earth.png'
                style={{
                    position: 'fixed',
                    left: '25%',
                    bottom: 0,
                    width: '50%',
                    zIndex: -1,
                    rotate: earthRotation + 'deg',
                    transition: 'transform 1s ease-out',
                }}
                initial={{ scale: 1.2, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5, ease: "easeOut" }}
            /> */}

            {/* <motion.div
                style={{
                    height: '100vh',
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    left: 0,
                    transition: 'transform 1s ease-out',
                    overflow: 'hidden'
                }}
                initial={{ scale: 0.2, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5, ease: "easeOut" }}
            >
                <Map
                    style={{ width: '100%', height: '100%', zIndex: 0, overflow: 'visible' }}
                    // mapStyle="mapbox://styles/ryelei/clu689myx006301ra2n7f92b5"
                    mapStyle="mapbox://styles/ryelei/cluxzyfzd004h01ppc85yc6nm"
                    mapboxAccessToken="pk.eyJ1IjoicnllbGVpIiwiYSI6ImNsOHh1OTV6MjAweGozcHBscnF4cDJzOGEifQ.46Qri6XOPhJUAW9j649-BA"
                    projection="globe"
                    initialViewState={{
                        longitude: 94.195397,
                        latitude: 25.86166,
                        zoom: 2
                    }}
                    attributionControl={false}
                    zoomControl={false}
                />
            </motion.div> */}
        </div>
    )
}
