import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Plotly from 'plotly.js-dist-min';
import * as d3 from 'd3';

const AncestryTreemap = () => {
    const [areaData, setAreaData] = useState({});
    const [selectedArea, setSelectedArea] = useState(null);

    // Function to load data and process it
    useEffect(() => {
        d3.csv("https://ie-tp02-game.s3.ap-southeast-2.amazonaws.com/data-for-visualization/suburb_ANCP_melt.csv").then(data => {
            const newData = {};
            data.forEach(row => {
                if (!newData[row.Area]) {
                    newData[row.Area] = { values: [], labels: [] };
                }
                newData[row.Area].values.push(+row.Number_of_people);
                newData[row.Area].labels.push(row.Ancestry);
            });
            setAreaData(newData);
            const firstArea = data[0] ? data[0].Area : null;
            setSelectedArea(firstArea);
        });
    }, []);

    // Function to update the treemap visualization
    const updateTreemap = (selectedArea) => {
        if (!selectedArea || !areaData[selectedArea]) return;

        const areaValues = areaData[selectedArea].values;
        const areaLabels = areaData[selectedArea].labels;
        const descriptions = areaLabels.map(label => cultureData[label] || "Description not available");

        const totalValue = areaValues.reduce((a, b) => a + b, 0);
        const data = [{
            type: "treemap",
            branchvalues: "total",
            labels: [selectedArea].concat(areaLabels),
            parents: [""].concat(new Array(areaLabels.length).fill(selectedArea)),
            values: [totalValue].concat(areaValues),
            texttemplate: "<b>%{label}</b><br>Total: %{value}<br>Percentage: %{percentParent:.2%}",
            textinfo: "label+value+percent parent",
            outsidetextfont: { size: 20, color: "#377eb8" },
            marker: { line: { width: 2 } },
            customdata: [""].concat(descriptions),
            hovertemplate: '<b>%{label}</b><br>Total: %{value}<br>Percentage: %{percentParent:.2%}<br>%{customdata}<extra></extra>'
        }];

        const layout = {
            margin: { l: 10, r: 10, b: 30, t: 10 },
            showlegend: false
        };

        Plotly.newPlot('treemap', data, layout);
    };

    // Effect to update the treemap when selection changes
    useEffect(() => {
        updateTreemap(selectedArea);
    }, [selectedArea]);

    return (
        <div>
            <Select
                options={Object.keys(areaData).map(area => ({ value: area, label: area }))}
                value={areaData[selectedArea] ? { value: selectedArea, label: selectedArea } : null}
                onChange={option => setSelectedArea(option ? option.value : null)}
                placeholder="Select an area"
                isClearable
            />
            <div id="treemap" style={{ width: '100%', height: 400, borderBottomLeftRadius: 18 }}></div>
        </div>
    );
};

export default AncestryTreemap;

const cultureData = {
    'Australian': 'Australian culture is a vibrant blend of influences,<br>\
characterized by a laid-back attitude, strong friendships,<br>\
and a love of nature. With a passion for sports and respect<br>\
for diversity, Australians prioritize family time, outdoor<br>\
activities, and work-life balance. It is also known for <br>\
its warm spirit, quirky humor, and love of beach culture.\
',
    'English': "English culture is a rich tapestry of history, heritage, and tradition.<br>\
It's characterized by a strong sense of nostalgia, love of tea and<br>\
pubs, and a fascination with royalty and pageantry. From<br>\
Shakespeare to the Beatles, England's cultural exports have<br>\
shaped the world, and its iconic landmarks like Buckingham<br>\
Palace and Stonehenge continue to inspire and awe.\
",
    'Chinese': 'Chinese immigration to Australia began during the gold rushes<br>\
of the 1850s, leading to thriving communities by 1861. <br>\
Initially drawn by gold, Chinese settlers diversified into<br>\
agriculture, commerce, and various trades after the rush. <br>\
They established cultural institutions and popularized<br>\
Chinese New Year celebrations across cities and rural towns.\
',
    'Indian': "Indian immigration to Australia began in the 19th century with<br>\
laborers accompanying British subjects. The White Australia<br>\
Policy restricted immigration until India's independence in<br>\
1947, when British citizens and Anglo-Indians migrated to<br>\
Australia. Relaxation of the policy later allowed a broader range<br>\
of Indians, including professionals, to immigrate.\
",
    'Torres Strait Islander': 'They are the Indigenous Melanesian people of the<br>\
Torres Strait Islands, which are part of the state of<br>\
Queensland, Australia. Ethnically distinct from the Aboriginal<br>\
peoples of the rest of Australia. Today there are many more<br>\
Torres Strait Islander people living in mainland Australia<br>\
(nearly 28,000) than on the Islands (about 4,500).\
',
    'Australian Aboriginal': 'Aboriginal Australians, with a history spanning 65,000<br>\
years, comprise diverse Indigenous groups. They share a<br>\
complex genetic history and self-identify as a single group<br>\
only in the last 200 years. Aboriginal identity evolves, <br>\
influenced by lineage and community acceptance. Their<br>\
rich culture endures through art, music, and traditions.\
',
    'Irish': "Irish culture is a rich and vibrant heritage, shaped by Celtic<br>\
traditions, folklore, and history. It's characterized by warm<br>\
hospitality, love of music and dance, and a strong sense of<br>\
community. From pubs to festivals, Ireland's culture is famous<br>\
for its friendly atmosphere, stunning landscapes, and iconic<br>\
symbols like the shamrock and Celtic cross.\
",
    'Scottish': "Scottish culture is a proud and vibrant heritage, shaped by<br>\
rugged landscapes, rich history, and warm hospitality. It's<br>\
characterized by a strong sense of national identity, love of<br>\
traditional music and dance, and a fondness for whisky and<br>\
haggis. From the Highlands to the Lowlands, Scotland's<br>\
culture is famous for its iconic symbols like the kilt, tartan,<br>\
and thistle, and its warm and welcoming people.\
",
    'Welsh': "Welsh culture is a unique and vibrant heritage, shaped by a rich<br>\
history, stunning landscapes, and a strong sense of community.<br>\
It's characterized by a love of music, poetry, and storytelling, <br>\
and a proud tradition of singing and folk dance. From the<br>\
mountains to the valleys, Wales is famous for its warm hospitality,<br>\
iconic castles, and the ancient Celtic language and customs that<br>\
continue to thrive today.\
",
    'German': "Germans were among the first European settlers in Australia, <br>\
with 10,000 arriving during the gold rush. However, they faced<br>\
persecution and social isolation during World Wars I and II, <br>\
leading to a significant decline in their population and erasure<br>\
of their cultural impact on Australian society.\
",
    "Greek": "Greece, a country with ancient roots and a rich legacy, borders Albania,<br>\
North Macedonia, Bulgaria, and Turkey, and is known for its islands, <br>\
hilosophy, and strong cultural identity. Greek migration occurred during<br>\
the gold rush, between the World Wars, and post-WWII. Migration<br>\
slowed as Europe recovered, and most Greek-born Australians<br>\
arrived before 1971, with minimal migration since.\
",
    "Vietnamese": "Vietnamese migration to Australia began with refugees in the<br>\
1970s and 1980s, followed by wives of Australian troops and<br>\
adopted orphans. Today, many arrive as skilled migrants or<br>\
family stream immigrants seeking economic and educational<br>\
opportunities. Some minorities have also sought asylum due<br>\
to political or religious persecution.\
",
    "Italian": "Italy is home to the epicentre of the Roman Empire, the hub of<br>\
Catholicism and the birthplace of the Renaissance. Italian<br>\
migrants arrived in Australia from southern regions like Sicily, <br>\
Calabria, and Campania, seeking better economic opportunities.<br>\
Many viewed their migration as temporary and didn't acculturate,<br>\
leading to social and cultural isolation. As a result, older<br>\
Italian-Australians may not speak English well and remain<br>\
isolated, unlike younger generations.\
",
    "Others": "Australians identify with over 300 different ancestries."
};
