import { Button, ConfigProvider } from 'antd'
import React from 'react'

export default function LanguageGameBtn(props) {
    const { height, width, fontSize, text, handleClick, playLoading } = props
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: `#dcd2b6`,
                        colorPrimaryHover: `#B8B1A0`,
                        colorPrimaryActive: 'B8AF98'
                    },
                },
            }}
        >
            <Button type="primary" size="large" style={{ height: height, width: width, fontSize: fontSize, color: '#250407', fontFamily: 'Georgia', border: '15px solid #84635a' }}
                onClick={handleClick} loading={playLoading}>
                {text}
            </Button>
        </ConfigProvider>
    )
}
