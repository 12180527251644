import * as React from "react";
import { useRef } from "react";
import { motion, sync, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import './SideBar.css'
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons'
import { useState } from "react";

const side = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      delay: 0.2,
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.3,
      stiffness: 400,
      damping: 40
    }
  }
};


export const SideBar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [displayStyle, setDisplayStyle] = useState('none')
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const switchBar = () => {
    toggleOpen()
    if (isOpen) {
      setTimeout(() => {
        setDisplayStyle('none')
      }, 500)
    } else {
      setDisplayStyle('block')
    }
  }

  return (
    <>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        style={{ display: displayStyle }}
      >
        <motion.div className="background" variants={side} />
        <Navigation isOpen={isOpen} />

      </motion.nav>
      <div style={{ position: 'absolute', left: 10, top: 8, zIndex: 999, padding: '1px 6px', borderRadius: 50, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, cursor: 'pointer' }}>
        <div onClick={switchBar} style={{ height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {isOpen ? <CloseOutlined style={{ fontSize: 23 }} /> : <UnorderedListOutlined style={{ fontSize: 23 }} />}

        </div>
      </div>
    </>
  );
};
