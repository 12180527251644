import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactiveButton from 'reactive-button';
import 'swiper/css';
import 'swiper/css/effect-cards';
import './GameMenu.css';
import { Col, Row, Tooltip, message } from 'antd';

// Define useQuery as a custom hook inside the component or in its own module
function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export default function GameMenu() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const query = useQuery();
  const country = query.get('country');
  const mode = query.get('mode');
  const [hoverType, setHoverType] = useState(null);

  useEffect(() => {
    // Check if country is not one of the specified names and mode is not 'game' or 'library'
    if (!['China', 'England', 'India', 'Australia', 'Thailand', 'Vietnam'].includes(country)) {
      navigate('/page-error')
    } else {
      if (mode) {
        localStorage.setItem('mode', mode);
      }
      if (country) {
        localStorage.setItem('country', country);
      }
    }
  }, [country, mode, navigate]);

  const selectType = (type) => {
    switch (type) {
      case 'attraction':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?country=${country}&type=${type}`);
        break;
      case 'food':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?country=${country}&type=${type}`);
        break;
      case 'language':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?country=${country}&type=${type}`);
        break;
      default:
        messageApi.warning('Something error, please try again.');
    }
  }

  return (
    <>
      {contextHolder}
      <div className='menu-container'>
        <motion.div className='menu-head' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>Select a Game Type of <span style={{ color: '#EFB00A' }}>{country}</span>:</motion.div>
        <Row gutter={50} style={{ width: '100%', height: '20%', position: 'absolute', bottom: 0 }}>
          <Col span={8} className='menu-col'>
            <motion.div initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }} onMouseEnter={() => setHoverType('attraction')} onMouseLeave={() => setHoverType(null)}>
              <ReactiveButton rounded onClick={() => selectType('attraction')} color="violet" idleText={<>
                <p>Place</p></>}
              />
            </motion.div>
          </Col>
          <Col span={8} className='menu-col'>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3, duration: 0.5 }} onMouseEnter={() => setHoverType('food')} onMouseLeave={() => setHoverType(null)}>
              <ReactiveButton rounded onClick={() => selectType('food')} color="violet" idleText={<>
                <p>Food</p></>}
              />
            </motion.div>
          </Col>
          <Col span={8} className='menu-col'>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6, duration: 0.5 }}>
              <ReactiveButton rounded onClick={() => selectType('language')} color="violet" idleText={<>
                <p>Language</p></>}
              />
            </motion.div>
          </Col>
        </Row>
      </div>
      <motion.div initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }} style={{ height: '30vh', width: '100vw', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255,255,255,0.8) 90%)', position: 'absolute', top: '70%', zIndex: 1 }} />

    </>
  );
}
