import React, { useEffect, useRef, useState } from 'react';
import './GameStyle.css';
import { Col, ConfigProvider, Modal, Progress, Row, Spin, Tour, message, notification } from 'antd';
import ReactiveButton from 'reactive-button';
import StreetView from '../webpart/StreetView';
import axios from 'axios';
import GameSummary from './GameSummary';
import GameRightPanel from './GameRightPanel';

export default function GameOngoingMain(props) {
  const [selected, setSelected] = useState(null);
  const [questionNo, setQuestionNo] = useState(0);
  const [questionState, setQuestionState] = useState(0)
  const [question, setQuestion] = useState();
  const [tourOpen, setTourOpen] = useState(false);
  const [correctNum, setCorrectNum] = useState(0)
  const [summary, setSummary] = useState(false)
  const [questionList, setQuestionList] = useState([])
  const [messageApi, contextHolder] = message.useMessage()
  const [api, notificationHolder] = notification.useNotification();
  const { country, type } = props

  // Refs for tour targets
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null)

  const modalItem = {
    content: (
      <>
        <Row gutter={20}>
          <Col span={8}>
            <img src={question?.img_path} style={{ width: '100%', borderRadius: 10 }} />
          </Col>
          <Col span={16}>
            {question?.correct_answer.description}
          </Col>
        </Row>
      </>
    ),
    footer: (_) => (
      <>
        <ReactiveButton rounded idleText={questionNo === 4 ? 'View Summary' : 'Next Question'} onClick={() => {
          Modal.destroyAll()
          setSelected(null)
          if (questionNo === 4) {
            setSummary(true)
            api.destroy()
          } else {
            api.destroy()
            setQuestion(questionList[questionNo + 1])
            setQuestionNo(questionNo + 1)
          }
        }
        }
        />

      </>
    ),
    closeIcon: false,
    maskClosable: false,
    width: '50%'
  }


  const steps = [
    {
      title: 'Read The Question',
      description: 'Read the question on the top.',
      target: () => ref1.current,
    },
    {
      title: 'Drag Image',
      description: 'Drag the image to view the street view.',
      cover: <video autoPlay loop muted src='https://ie-project.s3.ap-southeast-2.amazonaws.com/game-tour/map-teach.mp4' style={{ width: '100%', height: '100%' }} />,
      target: () => ref2.current,
    },
    {
      title: 'Select an Option',
      description: 'Click one of the options to check the result.',
      target: () => ref3.current,
    },
    {
      title: 'See the Progress',
      description: 'Check the progress of this round on the top.',
      target: () => ref4.current,
    }
  ];

  useEffect(() => {
    setQuestion(null)
    axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/games/attraction?culture=attraction&country=${country}`).then((res => {
      setQuestionList(res.data)
      setQuestion(res.data[questionNo]);
      if (!localStorage.getItem('placeTourOpen')) {
        setTimeout(() => {
          setTourOpen(true);
          localStorage.setItem('placeTourOpen', 'true');
        }, 1500)
      }

    })).catch((err) => {
      messageApi.warning('Please use a valid culture type!')
    });
  }, []);

  const handleSelect = (index) => {
    setSelected(index);
  };

  const getResult = (index) => {
    setQuestionState(questionState + 20)
    if (index != null) {
      if (index === question.correct_answer.id) {
        setCorrectNum(correctNum + 1)
        api['success']({ message: 'Correct Answer!' })
        Modal.success({ ...modalItem, title: 'Correct Answer!' })
      } else {
        api['error']({ message: 'Wrong Answer!' })
        Modal.error({ ...modalItem, title: 'Wrong Answer.' })
      }

    } else {
      messageApi.warning('Please select an option')
    }

  }


  return (
    <>
      {contextHolder}
      {notificationHolder}
      <div className='ongoing-place-game-layout'>

        {question ?
          <>
            {summary ? <>
              <div style={{ background: 'rgb(255,255,255,0.9)', width: '80vw', height: '80vh', position: 'absolute', top: '10vh', left: '10vw', borderRadius: 18 }}>
                <GameSummary round={questionNo + 1} correct={correctNum} country={country} />
              </div>
            </> :
              <>
                <div style={{ height: '20vh', background: 'linear-gradient(to bottom, black, transparent)', paddingInlineStart: '10vw', paddingInlineEnd: '10vw', color: 'white', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10 }}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Progress: {
                          colorText: 'white',
                          remainingColor: '#64625F'
                        },
                      },
                    }}
                  >
                    <Progress ref={ref4} type="line" percent={questionState} />
                  </ConfigProvider>
                  <div ref={ref1} style={{ fontSize: '2vw' }}>
                    {question?.question}
                  </div>
                </div>
                <div ref={ref2} style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, bottom: 0, left: 0 }}>
                  <StreetView key={question?.street_view_url} src={question?.street_view_url} />
                </div>
                <GameRightPanel
                  question={question}
                  selected={selected}
                  onSelect={handleSelect}
                  onResult={getResult}
                  questionNo={questionNo}
                  ref3={ref3}
                />
              </>
            }
          </>
          :
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <Spin size="large" style={{ fontSize: 80 }}>
              <div className="content" />
            </Spin>
          </div>
        }


        <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
      </div>
    </>
  );
}
