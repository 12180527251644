import React from 'react'
import { motion } from 'framer-motion';
import { Col, Row } from 'antd';
import ReactiveButton from 'reactive-button';
import { useNavigate } from 'react-router-dom';

export default function GameSummary(props) {
    const { round, correct, country } = props
    const navigate = useNavigate()
    return (
        <motion.div style={{ height: '100%', width: '100%', fontFamily: 'Comic Sans MS' }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div style={{ height: '20%', position: 'absolute', top: 0, paddingInlineStart: 20 }}>
                <h1 style={{ margin: 0 }}>
                    Game Summary
                </h1>
                <h2 style={{ margin: 0 }}>Your score is:</h2>

            </div>
            <div className='summary-content'>
                <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                    {
                        correct === 5 &&
                        <>
                            <img src='/game-icon/summary-icon/gold.png' alt='gold' />
                            <motion.h1 style={{ color: '#fdbf00', padding: 20 }}
                                initial={{ opacity: 0, y: -50, scale: 3 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >{correct} of {round}</motion.h1>
                            <h2>Excellent! You are a genius in the cultural game!</h2>
                        </>
                    }
                    {
                        correct === 4 &&
                        <>
                            <img src='/game-icon/summary-icon/silver.png' alt='silver' />
                            <motion.h1 style={{ color: '#9f9f9f', padding: 20 }}
                                initial={{ opacity: 0, y: -50, scale: 2.5 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >{correct} of {round}</motion.h1>
                            <h2>Great! You are an expert in cultural exchange!</h2>
                        </>
                    }
                    {
                        correct === 3 &&
                        <>
                            <img src='/game-icon/summary-icon/bronze.png' alt='bronze' />
                            <motion.h1 style={{ color: '#d4ac82', padding: 20 }}
                                initial={{ opacity: 0, y: -50, scale: 2 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >{correct} of {round}</motion.h1>
                            <h2>Good! Work hard to achieve the goal!</h2>
                        </>
                    }
                    {
                        correct < 3 &&
                        <>
                            <img src='/game-icon/summary-icon/happy.png' alt='smile' />
                            <motion.h1 style={{ color: 'black', WebkitTextFillColor: 'transparent', WebkitTextStroke: 1, padding: 20 }}
                                initial={{ opacity: 0, y: -50, scale: 1.5 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >{correct} of {round}</motion.h1>
                            <h2>Face everything with a smile, you are the best!</h2>
                        </>
                    }
                </div>
            </div>
            <Row className='summary-footer'>
                <Col span={12} className='summary-footer-col'>
                    <ReactiveButton rounded outline idleText='Play Again' height={'50%'} width={'40%'} style={{ fontSize: '1vw' }} onClick={() => navigate(0)} />
                </Col>
                <Col span={12} className='summary-footer-col'>
                    <ReactiveButton rounded idleText='Back to Menu' height={'50%'} width={'40%'} style={{ fontSize: '1vw' }} onClick={() => navigate(`/games/game-menu?country=${country}`)} />
                </Col>
            </Row>
        </motion.div>
    )
}
