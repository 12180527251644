import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import './GameInitial.css';
import { EffectCoverflow } from 'swiper/modules';
import ReactiveButton from 'reactive-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

export default function GameInitial() {
    const [selectedCountry, setSelectedCountry] = useState('Australia');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(useLocation().search)
    const mode = urlParams.get('mode')
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);


    useEffect(() => {
        if (mode != 'game' && mode != 'library') {
            navigate('/page-error')
        } else {
            localStorage.setItem('mode', mode)
        }
    }, [mode])

    const handleCountrySelect = (country, index) => {
        setSelectedCountry(country);
        setActiveIndex(index);
    };

    return (
        <>
            <div className='initial-container'>

                <motion.h1
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ textAlign: 'center', fontSize: '3vw' }}
                >Select a country's culture:</motion.h1>

                <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}>
                    <Swiper
                        onSwiper={setSwiper => swiperRef.current = setSwiper}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        spaceBetween={50}
                        coverflowEffect={{
                            slideShadows: true,
                        }}
                        modules={[EffectCoverflow]}
                        className="initial-swiper"
                        onSlideChange={(swiper) => {
                            handleCountrySelect(swiper.slides[swiper.activeIndex].getAttribute('data-country'), swiper.activeIndex);
                        }}
                    >
                        {['Australia', 'China', 'England', 'India', 'Thailand', 'Vietnam'].map((country) => (
                            <motion.div key={country} animate={{ scale: activeIndex === ['Australia', 'China', 'England', 'India', 'Thailand', 'Vietnam'].indexOf(country) ? 1.1 : 1 }} transition={{ duration: 1, ease: "easeInOut" }}>
                                <SwiperSlide
                                    onClick={() => swiperRef.current?.slideTo(['Australia', 'China', 'England', 'India', 'Thailand', 'Vietnam'].indexOf(country))}
                                    data-country={country}
                                    style={{
                                        transform: activeIndex === ['Australia', 'China', 'England', 'India', 'Thailand', 'Vietnam'].indexOf(country) ? 'scale(1.1)' : 'scale(1)'
                                    }}>
                                    {mode === 'game' && <img src={`/country-icon/${country.toLowerCase()}.jpg`} alt={country} />}
                                    {mode === 'library' && <img src={`/country-icon/${country.toLowerCase()}-2.jpg`} alt={country} />}
                                    <div className='country-slider-content'>{country}</div>
                                </SwiperSlide>
                            </motion.div>
                        ))}

                    </Swiper>
                </motion.div>

                <div style={{ textAlign: 'center' }}>
                    <Button ghost shape='circle' size='large' onClick={() => swiperRef.current?.slidePrev()} icon={<ArrowLeftOutlined />} style={{ marginRight: 20 }}></Button>

                    <ReactiveButton rounded idleText={mode === 'library' ? 'VIEW LIBRARY' : 'PLAY GAME'} color={mode === 'library' && 'yellow'} style={{
                        width: 300,
                        height: 70,
                        fontSize: '1.5em'
                    }} onClick={() => {
                        if (mode === 'game') {
                            navigate(`/games/game-menu?country=${selectedCountry}`)
                        } else {
                            localStorage.setItem('country', selectedCountry)
                            navigate(`/library?country=${selectedCountry}`)
                        }
                    }
                    } />
                    <Button ghost shape='circle' size='large' onClick={() => swiperRef.current?.slideNext()} icon={<ArrowRightOutlined />} style={{ marginLeft: 20 }}></Button>
                </div>
            </div>
            {
                mode === 'game' &&
                <motion.div initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }} style={{ height: '30vh', width: '100vw', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255,255,255,0.8) 90%)', position: 'absolute', top: '70%', zIndex: 1 }} />
            }
            {
                mode === 'library' &&
                <motion.div initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }} style={{ height: '100vh', width: '100vw', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(233, 128, 15,0.8) 90%)', position: 'absolute', top: '50%', zIndex: 1 }} />
            }
        </>
    );
}
