import React, { useRef } from 'react'
import { Row, Col } from 'antd'
import { useInView } from "framer-motion"

export default function OurAim() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true })
    return (
        <div style={{ width: '100%', background: 'linear-gradient(to bottom, rgb(255,255,255,0.1), rgb(255,255,255,0.8) 90%)' }}>
            <div ref={ref} style={{ width: '80%', marginLeft: '10%', transform: isInView ? "none" : "translateY(200px)", opacity: isInView ? 1 : 0, transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s" }}>
                <div style={{ fontSize: '1.5vw', textAlign: 'center', color: '#EFB00A', width: '50%', background: 'rgb(0,0,0,0.5)', marginLeft: '25%', borderRadius: 10 }}>
                    The Aim of VICulture Playground is to...
                </div>
                <h1 style={{ fontSize: '3vw', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                    Connect Globally, Embrace Culturally.
                </h1>
                <Row style={{
                    width: '60%',
                    marginLeft: '20%',
                    height: 300,
                    backgroundImage: 'url(/home-mode/cultural-diversity-2.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 10,
                }}>
                    <div style={{ height: '100%', width: '100%', borderRadius: 10, background: 'linear-gradient(to right, rgb(0,0,0,0.5), transparent 90%)' }}>
                        <div style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                            borderRadius: 10,
                            background: 'linear-gradient(to right, rgb(0,0,0,0.5), transparent 90%)'
                        }}>
                            <div style={{
                                paddingLeft: '10%',
                                paddingTop: '10%',
                                fontSize: '2vw',
                                color: 'white',
                                fontWeight: 'bold'
                            }}>Foster understanding, confidence, and meaningful engagement within Australia's diverse multicultural landscape.</div>
                            {/* <Button size='large' shape='circle' icon={<MenuOutlined />} style={{ position: 'absolute', right: '10px', bottom: '10px', zIndex: 999 }}></Button> */}
                        </div>
                    </div>

                </Row>
                <Row style={{ width: '60%', marginLeft: '20%', height: 300, marginTop: 10 }}>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <div style={{
                            height: '100%', width: '100%', backgroundImage: 'url(/home-mode/cultural-diversity-3.jpg)', borderRadius: 10, position: 'relative', backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}>
                            <div style={{ height: '100%', width: '100%', borderRadius: 10, background: 'linear-gradient(to bottom, rgb(0,0,0,0.6), transparent 90%)' }}>
                                <div style={{
                                    paddingLeft: '10%',
                                    paddingTop: '10%',
                                    paddingRight: '10%',
                                    fontSize: '1.5vw',
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>Provide them with a comprehensive online cultural learning hub.</div>
                                {/* <Button size='large' shape='circle' icon={<MenuOutlined />} style={{ position: 'absolute', right: '10px', bottom: '10px', zIndex: 999 }}></Button> */}
                            </div>
                        </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <div style={{
                            height: '100%', width: '100%', backgroundImage: 'url(/home-mode/victoria.jpg)', borderRadius: 10, position: 'relative', backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}>
                            <div style={{ height: '100%', width: '100%', borderRadius: 10, background: 'linear-gradient(to bottom, rgb(0,0,0,0.6), transparent 90%)' }}>
                                <div style={{
                                    paddingLeft: '10%',
                                    paddingRight: '10%',
                                    paddingTop: '10%',
                                    fontSize: '1.5vw',
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>Empower international students in Victorians.</div>
                                {/* <Button size='large' shape='circle' icon={<MenuOutlined />} style={{ position: 'absolute', right: '10px', bottom: '10px', zIndex: 999 }}></Button> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
