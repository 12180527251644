import React, { useEffect, useRef, useState } from 'react'
import { useInView } from "framer-motion";
import './ModeDisplay.css'
import SectionContent from './SectionContent';
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function ModeDisplay() {
    const navigate = useNavigate()
    const ref1 = useRef(null);
    const isInView = useInView(ref1, { once: true })
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            // Adjust the rotation factor based on how much you want the earth to rotate on scroll
            setRotation(window.scrollY * 0.05);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='home-mode' style={{ backgroundColor: 'black', width: '100%', color: 'white' }}>
                <div style={{ width: '90%', height: '80%', padding: '5%' }}>
                    <div>
                        <div style={{ textAlign: 'center', fontSize: '4vw', fontWeight: 'bold' }}>Why Choose VICulture Playground?</div>
                        <div style={{ textAlign: 'center', fontSize: '2vw' }}>Experience different cultures in the most interesting way.</div>
                    </div>
                    <div style={{ width: '50%', marginTop: 30, marginLeft: '25%' }}>
                        <SectionContent />
                    </div>
                    <div ref={ref1} className='earth-sticky' style={{ transform: isInView ? "none" : "translateY(200px)", opacity: isInView ? 1 : 0, transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s" }}>
                        <img src='/home-mode/earth.png' alt='earth' style={{ width: '100%', transform: `rotate(${rotation}deg)` }} />
                    </div>
                </div>
            </div >
            <div style={{ height: '100vh', background: 'linear-gradient(to bottom, black, rgb(255,255,255,0.1))', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                    <div style={{ fontSize: '4vw', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>Are you ready for the adventure?</div>
                    <div style={{ textAlign: 'center', marginTop: 50 }}><Button type='primary' style={{ width: '40%', height: 80, fontSize: '2vw' }} onClick={() => {
                        localStorage.setItem('homeTour', true)
                        navigate('/games')
                    }}>Begin The Tour!</Button></div>
                </div>
            </div>
        </>
    )
}
