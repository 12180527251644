import React from 'react'
import ReactiveButton from 'reactive-button'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import GameBackground from '../webpart/GameBackground'

export default function ErrorPage() {
    const navigate = useNavigate()
    return (
        <div style={{ background: 'black', width: '100vw', height: '100vh', position: 'fix', top: 0 }}>
            <GameBackground />
            <div style={{ position: 'absolute', top: 0, left: 20, width: '100vw' }}>
                <motion.img initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }} src='/logo.jpg' alt='logo' style={{ height: 100, width: 200, cursor: 'pointer' }} onClick={() => navigate('/')} />
            </div>
            <div style={{ height: '80vh', width: '80vw', background: 'rgb(255,255,255,0.9)', borderRadius: 18, zIndex: 1000, position: 'absolute', top: '10vh', left: '10vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <motion.div
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ width: '80%', height: '80%', textAlign: 'center' }}>
                    <img src='/game-icon/unlink.png' alt='unlink' style={{ height: '40%', marginTop: '5%' }} />
                    <h2>Oops! This page doesn't seem to exist.</h2>
                    <ReactiveButton outline idleText='go back home' rounded onClick={() => navigate('/')} />
                </motion.div>
            </div>
        </div>
    )
}
